import { Title, Group } from '@mantine/core';

// @ts-ignore
import logo from '../assets/logo-1.png';

type Props = {
    size?: string;
};

const Logo = (props: Props) => {
    return (
        <Group gap={0}>
            <img src={logo} style={{width: props.size === 'sm' ? '30px' : '40px', margin: '16px', transform: 'rotate(350deg)'}}/>
            <Title order={1} style={{
                fontFamily: '\'Sahitya\', cursive',
                fontSize: props.size === 'sm' ? '22px' : '30px',
                margin: '5px 0 0 -7px'
            }}>watchly</Title>
        </Group>
    );
};

export default Logo;