import { apiClient, fetcher } from './apiClient';
import { Device } from './Device';

/**
 * The shape of the API response
 */
type LocationResponse = {
    id: string;
    name: string;
    address: string|null;
    city: string|null;
    state: string|null;
    zip: string|null;
    orgId: string;
    stationCount: number;
    createdAt: string;
    updateAt: string;
};


type Location = {
    id: string;
    name: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    stationCount: number;
    createdAt: string;
    updateAt: string;
};

const locationFetcher = async (url: string): Promise<Location> => {
    const locationResponse = await fetcher<LocationResponse>(url);

    // Convert Null values to empty strings to play nicely with forms
    return {
        id: locationResponse.id,
        name: locationResponse.name,
        address: locationResponse.address ? locationResponse.address : '',
        city: locationResponse.city ? locationResponse.city : '',
        state: locationResponse.state ? locationResponse.state : '',
        zip: locationResponse.zip ? locationResponse.zip : '',
        orgId: locationResponse.orgId,
        stationCount: locationResponse.stationCount,
        createdAt: locationResponse.createdAt,
        updateAt: locationResponse.updateAt,
    } as Location;
};

const deleteLocation = async (
    csrfToken: string | null,
    locationId: string,
): Promise<void> => {
    if (csrfToken === null) {
        return;
    }

    const deleteLocationResponse = await apiClient.delete<void>(
        `api/location/${locationId}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken,
            },
        },
    );
    return;
};

type UpdateLocationPayload = {
    name?: string;
    stationId?: string;
};


const updateLocation = async(
    csrfToken: string | null,
    locationId: string | undefined,
    payload: UpdateLocationPayload
): Promise<Location | null> => {

    if (csrfToken === null) {
        return null;
    }

    const updateLocationResponse = await apiClient.put<LocationResponse>(
        `api/location/${locationId}`,
        JSON.stringify(payload),
        {
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken
            }
        }
    );

    return {
        id: updateLocationResponse.data.id,
        name: updateLocationResponse.data.name,
        address: updateLocationResponse.data.address ? updateLocationResponse.data.address : '',
        city: updateLocationResponse.data.city ? updateLocationResponse.data.city : '',
        state: updateLocationResponse.data.state ? updateLocationResponse.data.state : '',
        zip: updateLocationResponse.data.zip ? updateLocationResponse.data.zip : '',
        orgId: updateLocationResponse.data.orgId,
        stationCount: updateLocationResponse.data.stationCount,
        createdAt: updateLocationResponse.data.createdAt,
        updateAt: updateLocationResponse.data.updateAt,
    } as Location;

};

const listLocationFetcher = async (): Promise<Location[]> => {
    return await fetcher<Location[]>(`api/locations`);
};

export { locationFetcher, listLocationFetcher, deleteLocation, updateLocation };
export type { Location };