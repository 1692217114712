import React, { ReactNode } from 'react';
import { Grid } from '@mantine/core';

import bg from "../../assets/bg.jpg";

interface AuthLayoutProps {
    children: ReactNode;
}

const AuthLayout = ({ children }: AuthLayoutProps) => {
    return (
        <Grid gutter={0} grow>
            {/* Left Column */}
            <Grid.Col
                span={5}
                style={{
                    backgroundColor: '#f8f9fa', // Optional background color
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                }}
            >
                {children}
            </Grid.Col>

            {/* Right Column */}
            <Grid.Col
                span={7}
                style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    backgroundImage: `url('${bg}')`,
                    backgroundSize: 'auto 100%',
                }}
            >
                {/*<div>Right Column Content</div>*/}
            </Grid.Col>
        </Grid>
    );
};

export default AuthLayout;
