import { Authorization } from './Authorization';
import { fetcher } from './apiClient';

/**
 * The shape of the API response
 */
type GetUserResponse = {
    id: string;
    name: string;
    email: string;
    orgId: string;
    createdAt: string;
    updatedAt: string;
};

type GetMeResponse = {
    user: GetUserResponse;
    authorizations: {
        id: string;
        teamId: string;
        userId: string;
        roleId: Authorization['roleId'];
    }[];
    profile: {
        id: string;
        name: string;
        smsNumber: string;
        email: string;
        avatarFilename: string;
        createdAt: string;
        updatedAt: string;
        dispatchCenterId: string|null;
        agencyId: string|null;
        userId: string|null;
    }
};

/**
 * Our internal representation of a User, decoupled from the API response shape
 */
type User = {
    id: string;
    name: string;
    orgId: string;
};

type Profile = {
    id: string;
    name: string;
    smsNumber: string|null;
    email: string;
};

// const mockUserResponse: GetUserResponse = {}

const coerceApiResponseIntoEntity = (apiResponse: GetUserResponse): User => {
    return {
        id: apiResponse.id,
        name: apiResponse.name,
        orgId: apiResponse.orgId,
    };
};

const coerceAuthorizationsIntoEntity = (
    apiResponse: GetMeResponse,
): Authorization[] => {
    return apiResponse.authorizations.map((authorization) => ({
        id: authorization.id,
        key: authorization.id, // for rendering in a React list
        teamId: authorization.teamId,
        roleId: authorization.roleId,
        userId: authorization.userId,
        userName: apiResponse.user.name,
        userEmail: apiResponse.user.email,
    }));
};

const coerceProfileIntoEntity = (
    apiResponse: GetMeResponse,
): Profile => {
    return {
        id: apiResponse.profile.id,
        name: apiResponse.profile.name,
        smsNumber: apiResponse.profile.smsNumber,
        email: apiResponse.profile.email
    };
};

/**
 * The GET /api/me route returns the current authenticated user.
 * It's essentially a variation of the /api/user/{userId} route.
 * The shape of the response matches the shape of a response from the GET /api/user/{userId} route.
 *
 * @throws AxiosError
 */
const meFetcher = async (): Promise<{
    user: User;
    authorizations: Authorization[];
}> => {
    const response = await fetcher<GetMeResponse>(`api/me`);

    return {
        user: coerceApiResponseIntoEntity(response.user),
        authorizations: coerceAuthorizationsIntoEntity(response)
    };
};

const userFetcher = async (userId: string): Promise<User> => {
    return coerceApiResponseIntoEntity(
        await fetcher<GetUserResponse>(`api/user/${userId}`),
    );
};

export { meFetcher, userFetcher };
export type { GetUserResponse, User };
