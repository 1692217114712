import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AppShell, Burger, Group, Menu, ActionIcon, NavLink, Stack, Text, UnstyledButton, Container } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Home from '../components/Home';
import LogInForm from '../components/authentication/LogInForm';
import SignUpForm from '../components/authentication/SignUpForm';

import Locations from '../components/location/Locations';
import LocationDetail from '../components/location/LocationDetail';
import LocationForm from '../components/location/LocationForm';

import StationDetail from '../components/station/StationDetail';
import StationForm from '../components/station/StationForm';

import Devices from  '../components/device/Devices';
import DeviceDetail from '../components/device/DeviceDetail';
import DeviceForm from '../components/device/DeviceForm';

import PasswordRecoveryForm from '../components/authentication/PasswordRecoveryForm';
import ProtectedRoute from '../components/ProtectedRoute';
import { useAuth } from '../hooks/useAuth';
import { IconHome2, IconBuildings, IconDevicesPc, IconLogout2 } from '@tabler/icons-react';
import Logo from '../components/Logo';
import './AppRoutes.css';
import DeviceClaimForm from '../components/device/DeviceClaimForm';

const getInitials = (name: string) => {
    // Split the string into words
    const words = name.trim().split(/\s+/);

    // Extract the first letter of the first two words, if available
    const initials = words.slice(0, 2).map(word => word[0].toUpperCase());

    // Join the initials into a string and return
    return initials.join('');
};

export const AppRoutes: React.FC = () => {

    const [opened, { toggle }] = useDisclosure();
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/sign-in');
    };

    const handleNav = (route: string) => {
        navigate(route);
        toggle();
    };

    return (
        <AppShell
            header={{height: user ? 60 : 0}}
            navbar={{ width: user ? 300 : 0, breakpoint: 'sm', collapsed: { desktop: true, mobile: !opened } }}
            padding="0"
            style={{background: '#f3f3f3'}}
        >
            {user ? (
            <>
                <AppShell.Header>
                    <Group h="100%" px="md" justify="space-between">
                        <Group>
                            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                            <Logo size={'sm'}/>
                        </Group>
                        <Group ml="xl" gap={16} visibleFrom="sm">
                            <UnstyledButton onClick={() => handleNav('/')}>Home</UnstyledButton>
                            <UnstyledButton onClick={() => handleNav('/locations')}>Locations</UnstyledButton>
                            <UnstyledButton onClick={() => handleNav('/devices')}>Devices</UnstyledButton>
                            <Menu shadow="md">
                                <Menu.Target>
                                    <ActionIcon variant="filled" radius="xl" aria-label="Settings" p="15">
                                        <Text size="sm" fw={700}>{user?.name ? getInitials(user?.name) : 'NA'}</Text>
                                    </ActionIcon>
                                </Menu.Target>

                                <Menu.Dropdown>
                                    <Menu.Item onClick={handleLogout}>
                                        Logout
                                    </Menu.Item>
                                </Menu.Dropdown>
                            </Menu>
                        </Group>
                    </Group>
                </AppShell.Header>
                <AppShell.Navbar p="md">
                    <Stack
                        h="100%"
                        bg="var(--mantine-color-body)"
                        align="stretch"
                        justify="space-between"
                        gap="md"
                    >
                        <div>
                            <Stack>
                                <NavLink
                                    label="Home"
                                    onClick={() => handleNav('/')}
                                    leftSection={<IconHome2 size="2rem" stroke={1.5} color="var(--mantine-color-blue-filled)"/>}
                                />
                                <NavLink
                                    label="My Locations"
                                    onClick={() => handleNav('/locations')}
                                    leftSection={<IconBuildings size="2rem" stroke={1.5} color="var(--mantine-color-yellow-filled)"/>}
                                />
                                <NavLink
                                    label="Devices"
                                    variant="filled"
                                    onClick={() => handleNav('/devices')}
                                    leftSection={<IconDevicesPc size="2rem" stroke={1.5} color="var(--mantine-color-red-filled)"/>}
                                />
                                <NavLink
                                    label="Logout"
                                    onClick={handleLogout}
                                    leftSection={<IconLogout2 size="2rem" stroke={1.5} color="black"/>}
                                />
                            </Stack>
                        </div>
                        <div className={'CopyRightText'}>© Common Software Co. LLC {new Date().getFullYear()}</div>
                    </Stack>
                </AppShell.Navbar>
            </>
            ) : ''}
            <AppShell.Main>
                <Routes>
                    <Route path="/sign-up" element={<SignUpForm />}/>
                    <Route path="/sign-in" element={<LogInForm />}/>
                    <Route path="/recover-password" element={<Container><PasswordRecoveryForm/></Container>}/>
                    <Route path="/" element={<ProtectedRoute><Container><Home /></Container></ProtectedRoute>}/>
                    <Route path="/locations" element={<ProtectedRoute><Container><Locations /></Container></ProtectedRoute>}/>
                    <Route path="/locations/new" element={<ProtectedRoute><Container><LocationForm /></Container></ProtectedRoute>}/>
                    <Route path="/location/:locationId" element={<ProtectedRoute><Container><LocationDetail /></Container></ProtectedRoute>}/>
                    <Route path="/location/:locationId/edit" element={<ProtectedRoute><Container><LocationForm /></Container></ProtectedRoute>}/>
                    <Route path="/location/:locationId/stations/new" element={<ProtectedRoute><Container><StationForm /></Container></ProtectedRoute>}/>
                    <Route path="/location/:locationId/station/:stationId" element={<ProtectedRoute><Container><StationDetail /></Container></ProtectedRoute>}/>
                    <Route path="/devices" element={<ProtectedRoute><Container><Devices /></Container></ProtectedRoute>}/>
                    <Route path="/devices/new" element={<ProtectedRoute><Container><DeviceForm /></Container></ProtectedRoute>}/>
                    <Route path="/device/claim" element={<ProtectedRoute><Container><DeviceClaimForm /></Container></ProtectedRoute>}/>
                    <Route path="/device/:deviceId/claim" element={<ProtectedRoute><Container><DeviceClaimForm /></Container></ProtectedRoute>}/>
                    <Route path="/device/:deviceId" element={<ProtectedRoute><Container><DeviceDetail /></Container></ProtectedRoute>}/>
                    <Route path="/device/:deviceId/edit" element={<ProtectedRoute><Container><DeviceForm /></Container></ProtectedRoute>}/>
                </Routes>
            </AppShell.Main>
        </AppShell>
    );
};
