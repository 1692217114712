import React, { useState } from 'react';
import {
    Button,
    Paper,
    Text,
    Skeleton,
    Anchor,
    Flex,
    Stack,
    Breadcrumbs,
    ActionIcon,
    Modal,
    Loader
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { listLocationFetcher, Location } from '../../data/Location';
import { AxiosError } from 'axios';
import { IconMapPinFilled, IconDeviceTv, IconTrash, IconPencil } from '@tabler/icons-react';
import { deleteLocation } from '../../data/Location';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useCsrf } from '../../hooks/useCsrf';

const defaultLocation = {
    id: '',
    name: ''
};

const Locations: React.FC = () => {

    const navigate = useNavigate();
    const { csrfToken } = useCsrf();
    const [activeLocation, setActiveLocation] = useState(defaultLocation);
    const [opened, { open, close }] = useDisclosure(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleNav = (route: string) => {
        navigate(route);
    };

    const {
        data: listLocationsResponse,
        isLoading,
        error,
        mutate
    } = useSWR<Location[], AxiosError>(
        { entity: '/locations' },
        listLocationFetcher,
    );

    const handleDelete = (location: Location) => {
        setActiveLocation(location);
        open();
    };

    const handleConfirmation = async () => {
        setIsDeleting(true);
        try {
            await deleteLocation(csrfToken, activeLocation?.id);
            setIsDeleting(false);
            close();
            notifications.show({
                title: 'Location Deleted',
                message: `Successfully deleted location ${activeLocation?.name}.`,
            });
            setActiveLocation(defaultLocation);
            await mutate();
        } catch (error: any) {
            setIsDeleting(false);
            close();
            console.error(`An error occurred trying to delete location ${activeLocation?.id}.`, error);
        }
    };

    const stationCountString = (stationCount: number) => {
        if (stationCount === 1) {
            return '1 station';
        } else if (stationCount > 1) {
            return `${stationCount} stations`;
        } else {
            return 'No stations';
        }
    };

    // const locationSting = (location: Location) => {
    //     // If no address, state, city return 'No location set
    //     // If address exists
    //     if (location.address && location.state && location.city && location.zip) {
    //         return `${location.address} ${location.city}, ${location.state} ${location.zip}`;
    //     } else {
    //         return 'No location set';
    //     }
    // };

    const locationString = (location: Location): string => {
        const { address, city, state, zip } = location;

        if (!address && !state && !city && !zip) {
            return 'No location set';
        }

        const parts = [
            address,
            city && state ? `${city},` : city,
            state,
            zip
        ].filter(Boolean);

        return parts.join(' ');
    };


    const loadingIndicator = (
        <div>
            <Flex justify={'space-between'} align={'center'} style={{marginTop: '16px', marginBottom: '16px'}}>
                <Skeleton  height={38} width="100" radius="md" />
                <Skeleton  height={32} width="100" radius="md" />
            </Flex>
            <Stack>
                <Skeleton  height={76} width="100%" radius="md" />
                <Skeleton  height={76} width="100%" radius="md" />
                <Skeleton  height={76} width="100%" radius="md" />
            </Stack>
        </div>
    );

    if (isLoading) {
        return loadingIndicator;
    }
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <Modal opened={opened} onClose={close} title="Danger Zone">
                <Stack gap="sm">
                    <Text style={{ marginBottom: '16px' }}>Are you sure you want to delete location <Text span fw={700}>{activeLocation?.name}</Text>?</Text>
                    <Button color="red" onClick={handleConfirmation} fullWidth>
                        {isDeleting ? <Loader color="gray" size={18}/> : 'Confirm Delete'}
                    </Button>
                    <Button color="gray" onClick={close} fullWidth>Cancel</Button>
                </Stack>
            </Modal>
            <Breadcrumbs style={{marginTop: '16px'}} separatorMargin="xs" separator={<Text size="sm" fw={500}>/</Text>}>
                <Anchor size="sm" fw={500} onClick={() => handleNav('/')}>Home</Anchor>
                <Text size="sm">Locations</Text>
            </Breadcrumbs>
            <Flex justify={'space-between'} align={'center'}>
                <h2>Locations</h2>
                <Button onClick={() => handleNav('/locations/new')} size={'xs'}>New Location</Button>
            </Flex>
            {listLocationsResponse?.map((location) => (
                <Paper key={location.id} shadow="xs" p="lg" style={{marginBottom: '16px'}}>
                    <Flex justify={'space-between'}>
                        <Stack gap="xs">
                            <Anchor onClick={() => handleNav(`/location/${location.id}`)}>{location.name}</Anchor>
                            <Text><IconMapPinFilled style={{height: '15px'}}/>{locationString(location)}</Text>
                            <Text><IconDeviceTv style={{height: '15px'}}/>{stationCountString(location?.stationCount)}</Text>
                        </Stack>
                        <div>
                            <ActionIcon style={{ marginRight: '8px' }} variant="filled" color="gray" aria-label="Settings" onClick={() => handleNav(`/location/${location.id}/edit`)}>
                                <IconPencil style={{ width: '70%', height: '70%' }} stroke={1.5} />
                            </ActionIcon>
                            <ActionIcon variant="filled" color="gray" aria-label="Settings" onClick={() => handleDelete(location)}>
                                <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                            </ActionIcon>
                        </div>
                    </Flex>
                </Paper>
            ))}
        </div>
    );
};

export default Locations;
