import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Station, stationFetcher } from '../../data/Station';
import { AxiosError } from 'axios';
import { Anchor, Breadcrumbs, Skeleton, Text } from '@mantine/core';

const StationDetail: React.FC = () => {
    const { stationId } = useParams();
    const {
        data: station,
        isLoading,
        error
    } = useSWR<Station, AxiosError>(
        `api/station/${stationId}`,
        stationFetcher,
    );

    const navigate = useNavigate();

    const handleNav = (route: string) => {
        navigate(route);
    };

    if (isLoading) return <Skeleton />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <Breadcrumbs style={{marginTop: '16px'}} separatorMargin="xs" separator={<Text size="sm" fw={500}>/</Text>}>
                <Anchor size="sm" fw={500} onClick={() => handleNav('/')}>Home</Anchor>
                <Text size="sm">Stations</Text>
                <Text size="sm">{station?.name}</Text>
            </Breadcrumbs>
            <h2>{station?.name}</h2>
        </div>
    );
};

export default StationDetail;
