import React from 'react';
import { useParams } from "react-router-dom";
import useSWR from 'swr';
import { Location, locationFetcher } from '../../data/Location';
import { AxiosError } from 'axios';
import { Skeleton, Text } from '@mantine/core';

const DeviceDetail: React.FC = () => {
    const { locationId } = useParams();
    const {
        data: location,
        isLoading,
        error
    } = useSWR<Location, AxiosError>(
        `api/location/${locationId}`,
        locationFetcher,
    );

    if (isLoading) return <Skeleton />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <h2>{location?.name}</h2>
            <Text>{location?.address}</Text>
            <Text>{location?.city}, {location?.state} {location?.zip}</Text>
        </div>
    );
};

export default DeviceDetail;
