import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Group,
  TextInput,
  Stack,
  Alert,
  Anchor,
  Skeleton,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { apiClient } from "../../data/apiClient";
import { useCsrf } from "../../hooks/useCsrf";
import { IconExclamationCircle } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";

import "./DeviceForm.css";
import useSWR from "swr";
import { deviceFetcher, Device, updateDevice } from "../../data/Device";
import { AxiosError } from "axios";

const DeviceForm: React.FC = () => {
  const { csrfToken } = useCsrf();
  const navigate = useNavigate();
  const { deviceId } = useParams();
  const [isCreating, setIsCreating] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const form = useForm({
    mode: "uncontrolled",
    initialValues: {
      name: "",
    },
    validate: {
      name: (value) => (value ? null : "Name is required"),
    },
  });
  const icon = <IconExclamationCircle />;
  const handleBack = () => {
    navigate(`/devices`);
  };

  const {
    data: deviceData,
    isLoading,
    mutate,
  } = useSWR<Device, AxiosError>(
    deviceId ? `api/device/${deviceId}` : null,
    deviceFetcher
  );

  useEffect(() => {
    if (deviceData) {
      form.setValues(deviceData);
    }
  }, [deviceData]);

  const handleSubmit = async (values: typeof form.values) => {
    setIsCreating(true);
    setErrorMessage("");
    try {
      if (deviceId) {
        await updateDevice(csrfToken, deviceId, values);
      } else {
        await apiClient.post(`api/devices`, JSON.stringify(values), {
          headers: {
            "Content-Type": "application/json",
            "X-XSRF-TOKEN": csrfToken,
          },
        });
      }
      setIsCreating(false);
      mutate();
      if (deviceId) {
        notifications.show({
          title: "Device Updated",
          message: `Successfully updated device ${values?.name}`,
        });
      } else {
        notifications.show({
          title: "Device Created",
          message: `Successfully created device ${values?.name}`,
        });
        navigate(`/devices`);
      }
    } catch (err: any) {
      setIsCreating(false);
      setErrorMessage(err?.message);
      console.error("Full object", err);
      console.error("err message", err?.message);
    }
  };

  const renderForm = (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <TextInput
          withAsterisk
          label="Name"
          placeholder="TV 4, next to the moose head"
          description="You can choose any name that will help you to identify the new device."
          key={form.key("name")}
          {...form.getInputProps("name")}
        />

        <TextInput
          disabled={!!deviceId}
          withAsterisk
          label="Id"
          placeholder="12345a11-b22b-333c-44ab-7eb4fe0cd4ca"
          description="The 32-character identifier for your new device. It should be printed on a sticker on the device case."
          key={form.key("id")}
          {...form.getInputProps("id")}
        />

        <Group justify="flex-end" mt="md">
          <Button type="submit" disabled={!form.isValid()} loading={isCreating}>
            {deviceId ? "Save" : "Create"}
          </Button>
        </Group>
        {errorMessage ? (
          <Alert variant={"light"} color="red" title="Error" icon={icon}>
            {errorMessage}
          </Alert>
        ) : (
          ""
        )}
      </Stack>
    </form>
  );

  return (
    <div className={"location-form"} style={{ paddingTop: "16px" }}>
      <Anchor onClick={handleBack}>Back</Anchor>
      <h2>{deviceId ? "Edit device" : "Add a device"}</h2>
      {deviceId && isLoading ? (
        <Stack align="flex-end">
          <Skeleton height={32} mt={6} radius="md" />
          <Skeleton height={32} mt={6} width="100" radius="md" />
        </Stack>
      ) : (
        renderForm
      )}
    </div>
  );
};

export default DeviceForm;
