import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Group, TextInput, Stack, Alert, Anchor, Skeleton } from '@mantine/core';
import { useForm } from '@mantine/form';
import { apiClient } from '../../data/apiClient';
import { useCsrf } from '../../hooks/useCsrf';
import { IconExclamationCircle } from '@tabler/icons-react';
import { locationFetcher, updateLocation, Location } from '../../data/Location';

import './LocationForm.css';
import useSWR from 'swr';
import { AxiosError } from 'axios';
import { notifications } from '@mantine/notifications';

const LocationForm: React.FC = () => {

    const { csrfToken } = useCsrf();
    const navigate = useNavigate();
    const { locationId } = useParams();
    const [ isCreating, setIsCreating ]  = useState(false);
    const [ errorMessage, setErrorMessage ]  = useState('');
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
        },
        validate: {
            name: (value) => (value ? null : 'Name is required'),
        },
    });
    const icon = <IconExclamationCircle />;
    const handleBack = () => {
        navigate('/locations');
    };

    const {
        data: locationData,
        isLoading,
        mutate
    } = useSWR<Location, AxiosError>(
        locationId ? `api/location/${locationId}` : null,
        locationFetcher
    );

    useEffect(() => {
        if (locationData) {
            form.setValues(locationData);
        }
    }, [locationData]);

    const handleSubmit = async (values: typeof form.values) => {
        setIsCreating(true);
        setErrorMessage('');
        try {
            if (locationId) {
                await updateLocation(csrfToken, locationId, values);
            } else {
                await apiClient.post<Location>(
                    'api/location',
                    JSON.stringify(values),
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-XSRF-TOKEN': csrfToken
                        }
                    });
            }

            setIsCreating(false);
            mutate();

            if (locationId) {
                notifications.show({
                    title: 'Location Updated',
                    message: `Successfully updated location ${values?.name}`,
                });
            } else {
                notifications.show({
                    title: 'Location Created',
                    message: `Successfully created location ${values?.name}`,
                });
                navigate(`/devices`);
            }

        } catch (err: any) {
            setIsCreating(false);
            setErrorMessage(err?.message);
            console.error('Full object', err);
            console.error('err message', err?.message);
        }
    };

    const renderForm = (
        <form onSubmit={form.onSubmit(handleSubmit)}>
            <Stack>
                <TextInput
                    withAsterisk
                    label="Name"
                    placeholder="Downtown"
                    key={form.key('name')}
                    {...form.getInputProps('name')}
                />

                <TextInput
                    label="Address"
                    placeholder=""
                    key={form.key('address')}
                    {...form.getInputProps('address')}
                />

                <Group justify="flex-end" gap="sm" grow>
                    <TextInput
                        label="City"
                        placeholder=""
                        key={form.key('city')}
                        {...form.getInputProps('city')}
                    />

                    <TextInput
                        label="State"
                        placeholder=""
                        key={form.key('state')}
                        {...form.getInputProps('state')}
                    />

                    <TextInput
                        label="Zip"
                        placeholder=""
                        key={form.key('zip')}
                        {...form.getInputProps('zip')}
                    />
                </Group>

                <Group justify="flex-end" mt="md">
                    <Button type="submit" disabled={!form.isValid()} loading={isCreating}>{locationId ? 'Save' : 'Create'}</Button>
                </Group>
                {errorMessage
                    ? (
                        <Alert variant={"light"} color="red" title="Error" icon={icon}>
                            {errorMessage}
                        </Alert>
                    )
                    : ''}
            </Stack>
        </form>
    );

    return (
        <div className={'location-form'} style={{paddingTop: '16px'}}>
            <Anchor onClick={handleBack}>Back</Anchor>
            <h2>{locationId ? 'Edit location' : 'Add a location'}</h2>
            {locationId && isLoading
                ? (<Stack align="flex-end">
                    <Skeleton height={32} mt={6} radius="md"/>
                    <Skeleton height={32} mt={6} width="100" radius="md"/>
                </Stack>)
                : renderForm}
        </div>
    );
};

export default LocationForm;
