import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Group, TextInput, Stack, Alert, Anchor } from '@mantine/core';
import { useForm } from '@mantine/form';
import { apiClient } from '../../data/apiClient';
import { useCsrf } from '../../hooks/useCsrf';
import { IconExclamationCircle } from '@tabler/icons-react';

import './StationForm.css';

const StationForm: React.FC = () => {

    const { csrfToken } = useCsrf();
    const navigate = useNavigate();
    const [ isCreating, setIsCreating ]  = useState(false);
    const [ errorMessage, setErrorMessage ]  = useState('');
    const form = useForm({
        mode: 'uncontrolled',
        initialValues: {
            name: '',
            address: '',
            city: '',
            state: '',
            zip: '',
        },

        validate: {
            name: (value) => (value ? null : 'Name is required'),
        },
    });
    const { locationId } = useParams();
    const icon = <IconExclamationCircle />;
    const handleBack = () => {
        navigate(`/location/${locationId}`);
    };

    const handleSubmit = async (values: typeof form.values) => {
        setIsCreating(true);
        setErrorMessage('');
        try {
            const newLocation = await apiClient.post(
                `api/location/${locationId}/stations`,
                JSON.stringify(values),
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-XSRF-TOKEN': csrfToken
                    }
                });

            setIsCreating(false);
            navigate(`/location/${locationId}`);

        } catch (err: any) {
            setIsCreating(false);
            setErrorMessage(err?.message);
        }
    };

    return (
        <div className={'location-form'} style={{paddingTop: '16px'}}>
            <Anchor onClick={handleBack}>Back</Anchor>
            <h2>Add a station</h2>
            <form onSubmit={form.onSubmit(handleSubmit)}>
                <Stack>
                    <TextInput
                        withAsterisk
                        label="Name"
                        placeholder="Bar"
                        key={form.key('name')}
                        {...form.getInputProps('name')}
                    />

                    <Group justify="flex-end" mt="md">
                        <Button type="submit" disabled={!form.isValid()} loading={isCreating}>Create</Button>
                    </Group>
                    {errorMessage
                        ? (
                            <Alert variant={"light"}  color="red" title="Error" icon={icon}>
                                {errorMessage}
                            </Alert>
                        )
                        : ''}
                </Stack>
            </form>
        </div>
    );
};

export default StationForm;
