import React from 'react';
import { Paper, Grid, Title, Text } from '@mantine/core';
import { IconBuildings, IconDeviceTv, IconDevicesPc } from '@tabler/icons-react';
import useSWR from 'swr';
import { AxiosError } from 'axios';
import { organizationSummaryFetcher, OrganizationSummary } from '../data/Organization';
import { useAuth } from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {

    const { user } = useAuth();
    const {
        data: organization,
        isLoading: isOrganizationLoading,
        error: errorGetError
    } = useSWR<OrganizationSummary, AxiosError>(
        `api/organization/${user?.orgId}/summary`,
        organizationSummaryFetcher,
    );
    const navigate = useNavigate();
    const handleNav = (route: string) => {
        navigate(route);
    };

    return (
        <div>
            <h2>{organization?.name}</h2>
            <Grid>
                <Grid.Col span={{base: 12, md: 12, lg: 4}}>
                    <Paper shadow="xs" withBorder p="xl" style={{background: 'var(--mantine-color-yellow-1)', cursor: 'pointer'}} onClick={() => handleNav('/locations')}>
                        <IconBuildings size="2rem" stroke={1.5} color="var(--mantine-color-yellow-filled)"/>
                        <Title order={3}>Locations</Title>
                        <Text>{organization?.counts?.locationCount} locations</Text>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 12, lg: 4}}>
                    <Paper shadow="xs" withBorder p="xl" style={{background: 'var(--mantine-color-blue-1)'}}>
                        <IconDeviceTv size="2rem" stroke={1.5} color="var(--mantine-color-blue-filled)"/>
                        <Title order={3}>Stations</Title>
                        <Text>{organization?.counts?.stationCount} stations</Text>
                    </Paper>
                </Grid.Col>
                <Grid.Col span={{base: 12, md: 12, lg: 4}}>
                    <Paper shadow="xs" withBorder p="xl" style={{background: 'var(--mantine-color-red-1)', cursor: 'pointer'}} onClick={() => handleNav('/devices')}>
                        <IconDevicesPc size="2rem" stroke={1.5} color="var(--mantine-color-red-filled)"/>
                        <Title order={3}>Devices</Title>
                        <Text>{organization?.counts?.deviceCount} devices</Text>
                    </Paper>
                </Grid.Col>
            </Grid>
        </div>
    );
}

export default Home;
