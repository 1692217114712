import { fetcher } from './apiClient';

/**
 * The shape of the API response
 */
type Organization = {
    id: string;
    name: string;
    createdAt: string;
    updateAt: string;
};

type OrgCounts = {
    locationCount: number;
    stationCount: number;
    deviceCount: number;
};

type OrganizationSummary = {
    id: string;
    name: string;
    createdAt: string;
    updateAt: string;
    counts: OrgCounts;
};

const organizationFetcher = async (url: string): Promise<Organization> => {
    const organizationResponse = await fetcher<Organization>(url);

    return {
        id: organizationResponse.id,
        name: organizationResponse.name,
        createdAt: organizationResponse.createdAt,
        updateAt: organizationResponse.updateAt,
    } as Organization;
};

const organizationSummaryFetcher = async (url: string): Promise<OrganizationSummary> => {
    const organizationResponse = await fetcher<OrganizationSummary>(url);

    return {
        id: organizationResponse.id,
        name: organizationResponse.name,
        createdAt: organizationResponse.createdAt,
        updateAt: organizationResponse.updateAt,
        counts: organizationResponse.counts,
    } as OrganizationSummary;
};

export { organizationFetcher, organizationSummaryFetcher };
export type { Organization, OrganizationSummary };