import React, { useEffect } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { AppRoutes } from './routes/AppRoutes';
import { createTheme, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';

// Add Mantine Styles
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';

const theme = createTheme({
    /** Put your mantine theme override here */
});

const App: React.FC = () => {
    useEffect(() => {
        const link = document.createElement('link');
        link.href = 'https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&family=Mogra&family=Red+Hat+Display:ital,wght@1,300..900&family=Sahitya:wght@400;700&display=swap';
        link.rel = 'stylesheet';
        document.head.appendChild(link);

        return () => {
            document.head.removeChild(link); // Clean up when the component unmounts
        };
    }, []);
    return (
        <MantineProvider theme={theme}>
            <Notifications />
            <Router>
                <AppRoutes />
            </Router>
        </MantineProvider>
    );
};

export default App;
