import React, { useState } from 'react';
import { Button, Stack, Text, Anchor } from '@mantine/core';
import AuthLayout from './AuthLayout';
import { appConfig } from '../../data/AppConfig';
import { useCsrf } from '../../hooks/useCsrf';
import Logo from '../Logo';
import googlelogo from '../../assets/google.png';

const LogInForm = () => {
    const { refreshCsrfToken } = useCsrf();
    const [isLoggingInGoogle, setIsLoggingInGoogle] = useState(false);

    const handleLoginWithGoogle = () => {
        setIsLoggingInGoogle(true);
        refreshCsrfToken().request.then(() => {
            window.location.assign(
                `${appConfig.REACT_APP_BACKEND_URL}/api/auth/redirect/google`,
            );
        });
    };

    return (
        <AuthLayout>
            <div style={{position: 'absolute', top: 0, left: 0}}>
                <Logo />
            </div>
            <Stack>
                <Text style={{
                    fontSize: '3rem',
                    fontFamily: "'Sahitya', cursive",
                    lineHeight: '2.8rem',
                    fontWeight: 600
                }}>Hi there!</Text>
                <Text style={{fontSize: '1rem', fontWeight: 400}}>Welcome to <strong>Watchly</strong>. Your Realtime Sportsbook.</Text>
                <Button
                    variant="outline"
                    loading={isLoggingInGoogle}
                    onClick={handleLoginWithGoogle}
                ><img src={googlelogo} style={{width: '25px', margin: '16px'}}/> Continue with
                    Google</Button>
                <Text style={{fontSize: '.8rem', fontWeight: 400}}>Don't have an account? <Anchor style={{fontSize: '.8rem', fontWeight: 400}}>Request an invite.</Anchor></Text>
            </Stack>
        </AuthLayout>
    );
};

export default LogInForm;
