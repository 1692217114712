import { fetcher } from './apiClient';

/**
 * The shape of the API response
 */
type Station = {
    id: string;
    name: string;
    createdAt: string;
    updateAt: string;
    locationId: string;
};

const stationFetcher = async (url: string): Promise<Station> => {
    const stationResponse = await fetcher<Station>(url);

    return {
        id: stationResponse.id,
        name: stationResponse.name,
        createdAt: stationResponse.createdAt,
        updateAt: stationResponse.updateAt,
        locationId: stationResponse.locationId,
    } as Station;
};

const listStationFetcher = async (url: string): Promise<Station[]> => {
    return await fetcher<Station[]>(url);
};

export { stationFetcher, listStationFetcher };
export type { Station };