import React from 'react';
import useSWR from 'swr';
import { Location, locationFetcher } from '../../data/Location';
import { listStationFetcher, Station } from '../../data/Station';
import { AxiosError } from 'axios';
import { Skeleton, Text, Button, Paper, Anchor, Breadcrumbs } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';

const LocationDetail: React.FC = () => {
    const navigate = useNavigate();
    const handleNav = (route: string) => {
        navigate(route);
    };
    const { locationId } = useParams();
    const {
        data: location,
        isLoading,
        error
    } = useSWR<Location, AxiosError>(
        `api/location/${locationId}`,
        locationFetcher,
    );
    const {
        data: stations,
        isLoading: isStationsLoading,
        error: errorGetError
    } = useSWR<Station[], AxiosError>(
        `api/location/${locationId}/stations`,
        listStationFetcher,
    );

    if (isLoading) return <Skeleton />;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <div>
            <Breadcrumbs style={{marginTop: '16px'}} separatorMargin="xs" separator={<Text size="sm" fw={500}>/</Text>}>
                <Anchor size="sm" fw={500} onClick={() => handleNav('/')}>Home</Anchor>
                <Anchor size="sm" fw={500} onClick={() => handleNav('/locations')}>Locations</Anchor>
                <Text size="sm">{location?.name}</Text>
            </Breadcrumbs>
            <h2>{location?.name}</h2>
            <Text>{location?.address}</Text>
            <Text>{location?.city}, {location?.state} {location?.zip}</Text>
            <hr/>
            <Text>Stations</Text>
            <Button onClick={() => handleNav(`/location/${locationId}/stations/new`)} size={'xs'} style={{marginBottom: '16px'}}>Add Station</Button>
            {stations?.map((station) => (
                <Paper key={station.id} shadow="xs" p="lg" style={{marginBottom: '16px'}}>
                    <Anchor onClick={() => handleNav(`/location/${locationId}/station/${station.id}`)}>{station.name}</Anchor>
                </Paper>
            ))}
        </div>
    );
};

export default LocationDetail;
