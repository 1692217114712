import { meFetcher } from '../data/User';
import { apiClient } from '../data/apiClient';
import { appConfig } from '../data/AppConfig';
import { AxiosError } from 'axios';
import useSWR from 'swr';

const useAuth = () => {

    const {
        data: userWithAuthorizations,
        isLoading,
        error,
        mutate,
    } = useSWR<Awaited<ReturnType<typeof meFetcher>>, AxiosError>(
        { entity: 'user/me' },
        meFetcher,
    ); // The Generic is only included here to get the right Type onto the 'error' object (instead of 'any')

    const logout = async () => {
        window.location.assign(`${appConfig.REACT_APP_FRONTEND_URL}/sign-in`);
        if (!error) {
            await apiClient
                .get(`${appConfig.REACT_APP_BACKEND_URL}/api/logout`)
                .then(() => mutate());
        }
    };

    if (error?.response?.status === 401) {
        // TODO: Sort out this logout
        // window.location.assign(`${appConfig.REACT_APP_FRONTEND_URL}/sign-in`);
    }

    return {
        user: userWithAuthorizations?.user ?? null,
        authorizations: userWithAuthorizations?.authorizations ?? null,
        isLoading,
        error,
        logout,
        mutate,
    };
};

export { useAuth };